<template>
  <section class="mx-6">
    <!-- titulo -->
    <div class="my-6 d-flex flex-column">
      <span class="secondary--text" v-text="'Procesos / ' + codigo_proceso_var" />
      <p class="d-flex align-center my-1 text-center">
        <v-icon v-if="status_value_var == true" class="mr-3" style="color: #73d2de !important">
          mdi-circle
        </v-icon>
        <span class="text-h6 secondary--text" v-text="nombre_proceso_var" />
        <v-menu offset-y v-if="id_estado_proceso_var === 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="ml-3" icon color="secondary" v-bind="attrs" v-on="on">
              <v-icon dark> mdi-cog </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
              <v-btn class="secondary--text text-no-style" text @click="HandlerBtnFtn">
                <v-icon size="25" class="mb-1 mr-2" color="cyan">mdi-circle</v-icon>
                {{ labelBtnCmp }} proceso con reserva
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="modalPrevencion = true" class="secondary--text text-no-style" text>
                <v-icon size="25" class="mb-2 mr-2" color="secondary">mdi-alert</v-icon>
                Generar prevención
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn @click="modalActualizacioHitos = true" class="secondary--text text-no-style" text>
                <v-icon size="25" class="mb-2 mr-2" color="secondary">mdi-reload</v-icon>
                Actualizar hitos de proceso
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-tooltip right left v-if="id_estado_proceso_var === 1 && [1, 11].includes(id_seguimiento_proceso_var)">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" class="ml-3" icon @click="$refs.modalCancelarProceso.show = true">
              <v-icon color="secondary">mdi-delete</v-icon>
            </v-btn>
          </template>
          <span> Cancelar proceso</span>
        </v-tooltip>

        <v-chip v-if="id_estado_proceso_var === 8" color="error" class="ml-4 white--text px-8">Cancelado</v-chip>
      </p>
    </div>

    <!-- tabs -->
    <v-tabs v-if="false" :show-arrows="$vuetify.breakpoint.xs" align-with-title background-color="bgMinsal"
      v-model="selected_tab_var" class="v-step-0" transition>
      <v-tab class="text-no-style mx-0">
        <v-icon>mdi-clipboard-text</v-icon>
        General
      </v-tab>
      <v-tab class="text-no-style mx-0">
        <v-icon>mdi-badge-account-outline</v-icon>
        Usuario supervisor
      </v-tab>
    </v-tabs>

    <!-- tabs content -->
    <v-tabs-items v-model="selected_tab_var" touchless>
      <!-- Listado de etapas -->
      <v-tab-item>
        <!-- detalle -->
        <div class="container_span">
          <p>
            <span v-text="'Suma de mercancías'" />
            <span class="secondary--text text-h6">
              {{
                Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }).format(suma_mercancias_var)
              }}
            </span>
          </p>
          <p>
            <span v-text="'Modalidad de compra'" />
            <span class="secondary--text text-h6" v-text="modalidad_compra_var" />
          </p>
          <p>
            <span v-text="'Mes inicio de proceso'" />
            <span class="secondary--text text-h6" v-text="moment(mes_inicio_var).format('MMMM YYYY')" />
          </p>
          <p>
            <span v-text="'Mes fin de proceso'" />
            <span class="secondary--text text-h6" v-text="moment(mes_fin_var).format('MMMM YYYY')" />
          </p>
        </div>

        <!-- tabla -->
        <data-table-component class="mt-12" :headers="HEADERS_ETAPAS_CONST" :items="list_etapas_var"
          :inhabilitar_paginacion="true" :tiene_paginacion="false">
          <!-- slot de fecha inicio -->
          <template #[`item.fecha_hora_inicio`]="{ item }">
            <span>
              {{
                item.fecha_hora_inicio
                ? moment(item.fecha_hora_inicio).format("DD/MM/YYYY")
                : ""
              }}
            </span>
          </template>
          <!-- slot de fecha fin -->
          <template #[`item.fecha_hora_fin`]="{ item }">
            <span>
              {{
                item.fecha_hora_fin
                ? moment(item.fecha_hora_fin).format("DD/MM/YYYY")
                : ""
              }}
            </span>
          </template>
          <!-- slot de duracion -->
          <template #[`item.duracion`]="{ item }">
            <span>
              {{
                item.fecha_hora_inicio && item.fecha_hora_fin
                ? moment(item.fecha_hora_fin).diff(
                  moment(item.fecha_hora_inicio),
                  "days"
                )
                : ""
              }}
            </span>
          </template>
        </data-table-component>
      </v-tab-item>
      <!-- Listado de supervisores -->
      <v-tab-item v-if="status_value_var">
        <div tile class="py-4 mt-8 mb-4 d-flex flex-column flex-sm-row">
          <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary"
            @click="show_modal_var = true">
            Agregar
          </v-btn>
        </div>
        <!-- tabla -->
        <span class="ml-1" v-text="'Listado de personas a invitar'" />
        <data-table-component :headers="HEADERS_SUPERVISORES_CONST" :items="list_supervisores_var"
          :total_registros="paginate_supervisores_var.total_rows" @paginar="PaginateFtn"
          v-models:pagina="paginate_supervisores_var.page" v-models:select="paginate_supervisores_var.per_page"
          :show_actions="true" :actions="ACTIONS_SUPERVISOR_CONST" @deleteSuperv="deleteSupervFtn">
          <!-- slot de nombre completo -->
          <template #[`item.nombre_completo`]="{ item }">
            <span>
              {{ nombreSupervFtn(item) }}
            </span>
          </template>
        </data-table-component>
      </v-tab-item>
    </v-tabs-items>

    <!-- footer -->
    <footer tile class="py-4 mt-8 d-flex flex-column flex-sm-row" color="bgMinsal">
      <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary" outlined
        @click="$router.back()">
        Volver
      </v-btn>
    </footer>

    <!-- modal agregar -->
    <v-dialog v-model="show_modal_var" persistent max-width="800">
      <v-card>
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="CloseModalFtn">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-row class="mx-sm-6">
            <v-col cols="12" class="d-flex flex-column">
              <span class="text-h6 secondary--text">Agregar supervisor</span>
              <span>
                Digite el correo para el usuario que será supervisor del proceso
              </span>
            </v-col>
            <v-col cols="12">
              <v-autocomplete v-model="supervisor_selected_var" :items="ctl_usuarios_var"
                :loading="loading_ctl_usuarios_var" :search-input.sync="search_ctl_usuarios_var"
                label="Correo electrónico" outlined item-text="email" return-object />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field label="Nombre completo" outlined readonly :value="nombreSupervFtn(supervisor_selected_var)" />
            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary" outlined
              @click="CloseModalFtn">
              Cancelar
            </v-btn>
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary" @click="AddSupervFtn">
              Registrar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- modal agregar prevención -->
    <v-dialog v-model="modalPrevencion" persistent max-width="800">
      <v-card>
        <div class="headerIcon">
          <v-icon class="secondary--text" @click="CloseModalPrev">
            mdi-close
          </v-icon>
        </div>
        <v-card-text>
          <v-row class="mx-sm-6">
            <v-col cols="12" class="d-flex flex-column">
              <div class="d-flex align-center">
                <span class="text-h6 secondary--text">Prevención </span>
                <v-icon color="secondary" class="ml-4">mdi-alert</v-icon>
              </div>
              <span>
                Una vez enviada, la presente será revisada por el Director
                DINAC. Manténgase pendiente del seguimiento
              </span>
            </v-col>
            <v-col cols="12">
              <v-textarea label="Motivo *" placeholder="En este proceso se debe tener especial cuidado por..." outlined
                v-model="prevForm.prevencion" @input="$v.prevForm.prevencion.$touch()"
                :error-messages="prevencionErrors" />
            </v-col>
            <v-col cols="12" sm="6">
              <v-file-input outlined required prepend-icon="" accept="application/pdf" prepend-inner-icon="mdi-paperclip"
                label="Documento adjunto *" v-model="prevForm.documento" @blur="$v.prevForm.documento.$touch()"
                :error-messages="DocErrors"></v-file-input>
            </v-col>
            <v-col cols="12" sm="6">
              <v-menu ref="menuFechaVencimiento" v-model="menuFechaVencimiento" :close-on-content-click="false"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field outlined v-model="fechaFormatted" label="Fecha de vencimiento *"
                    prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                    @blur="$v.prevForm.fecha_vencimiento.$touch()" :error-messages="FechaErrors" />
                </template>
                <v-date-picker title="es" :min="fechaMaximaFiltro" v-model="prevForm.fecha_vencimiento" locale="es-Es">
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- acciones -->
          <v-row cols="12" class="d-flex flex-column flex-sm-row mx-sm-4 mb-6">
            <v-btn class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary" outlined
              @click="CloseModalPrev">
              Cancelar
            </v-btn>
            <v-btn :loading="loadingPrev" class="button-extra text-capitalize mt-4 mt-sm-0 mx-8 mx-sm-4" color="secondary"
              @click="savePrevProceso()">
              Enviar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Modal para confirmar actualización de hitos -->
    <ConfirmationDialog :show="modalActualizacioHitos" btnConfirmar="Actualizar" btnCancelar="No, cancelar"
      title="¿Desea actualizar los hitos actualmente configurados en el proceso?"
      message="Esto importará la reciente configuración de hitos al proceso sin afectar aquellas que han sido configuradas."
      @close="modalActualizacioHitos = false" @confirm="actualizarHitosProceso" />

    <!-- Modal para confirmar cancelación de proceso -->
    <ModalCancelarProcesoComponent ref="modalCancelarProceso" @hideModal="hideModalCancelarProceso" @success="FetchDetailProcesoFtn"/>
  </section>
</template>

<script>
// importaciones de componentes globales
import DataTableComponent from "../../components/DataTableComponent.vue";
import ConfirmationDialog from "../../components/ConfirmationDialogComponent.vue";
import ModalCancelarProcesoComponent from "./components/ModalCancelarProcesoComponent.vue";
import LegacyValidations from "@/utils/legacy-validations";

// importaciones de librerias
import { required, helpers } from "vuelidate/lib/validators";
import moment from "moment";

const letter = helpers.regex("letter", /^[A-Za-zñÑáéíóúÁÉÍÓÚ0-9 -]*$/);

function fileTypeValid(value) {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
}

function fileNameValid(value) {
  if (!value) {
    return true;
  }
  let regAlfaNumPdf = /^[a-zA-Z0-9ñÑáéíóúÁÉÍÓÚ\-_\s]+(?=\.pdf$)/;
  let file = value;
  return regAlfaNumPdf.test(file.name);
}

export default {
  name: "DetailInterPresiView",

  components: {
    DataTableComponent,
    ConfirmationDialog,
    ModalCancelarProcesoComponent,
  },

  validations: {
    supervisor_selected_var: {
      required,
    },
    prevForm: {
      prevencion: { required, letter },
      documento: { 
        required, 
        fileTypeValid, 
        fileNameValid, 
        fileSizeValid: LegacyValidations.maxFileSizeRule(),
      },
      fecha_vencimiento: { required },
    },
  },

  data: () => ({
    // constantes
    HEADERS_ETAPAS_CONST: [
      { text: "Etapas", value: "nombre" },
      { text: "Fecha de inicio", value: "fecha_hora_inicio", align: "center" },
      { text: "Fecha de fin", value: "fecha_hora_fin", align: "center" },
      { text: "Duración (días)", value: "duracion", align: "center" },
    ],
    HEADERS_SUPERVISORES_CONST: [
      { text: "Nombre completo", value: "nombre_completo", align: "center" },
      {
        text: "Correo electrónico",
        value: "EmpleadoUnidad.Empleado.Usuario.email",
        align: "center",
      },
      { text: "acciones", value: "actions", align: "center", sortable: false },
    ],
    ACTIONS_SUPERVISOR_CONST: [
      {
        tooltip: {
          text: "Eliminar supervisor",
          color: "blueGrayMinsal",
        },
        icon: {
          color: "secondary",
          name: "mdi-delete",
        },
        eventName: "deleteSuperv",
      },
    ],

    // variables
    status_value_var: false,
    codigo_proceso_var: "",
    nombre_proceso_var: "",
    suma_mercancias_var: 0,
    modalidad_compra_var: "",
    mes_inicio_var: "",
    mes_fin_var: "",
    list_etapas_var: [],

    selected_tab_var: 0,

    // v -> modal
    show_modal_var: false,
    list_supervisores_var: [],
    paginate_supervisores_var: {
      page: 1,
      per_page: 10,
      total_rows: 0,
    },

    ctl_usuarios_var: [],
    loading_ctl_usuarios_var: false,
    search_ctl_usuarios_var: "",
    supervisor_selected_var: null,
    modalPrevencion: false,
    prevForm: {
      prevencion: null,
      documento: null,
      fecha_vencimiento: null,
      id_proceso_compra: null,
    },
    menuFechaVencimiento: false,
    loadingPrev: false,
    modalActualizacioHitos: false,

    modalCancelarProceso: false,
    id_estado_proceso_var: null,
    id_seguimiento_proceso_var: null,
  }),

  computed: {
    prevencionErrors() {
      const errors = [];
      if (!this.$v.prevForm.prevencion.$dirty) return errors;
      !this.$v.prevForm.prevencion.required &&
        errors.push("El campo motivo es requerido");
      !this.$v.prevForm.prevencion.letter &&
        errors.push("El  campo motivo no puede contener caracteres especiales");
      return errors;
    },
    DocErrors() {
      const errors = [];
      if (!this.$v.prevForm.documento.$dirty) return errors;
      !this.$v.prevForm.documento.required &&
        errors.push("El campo documento adjunto es requerido");
      !this.$v.prevForm.documento.fileTypeValid &&
        errors.push("El campo documento adjunto debe ser un archivo PDF");
      !this.$v.prevForm.documento.fileNameValid &&
        errors.push(
          "El campo documento adjunto no puede contener caracteres especiales"
        );
      !this.$v.prevForm.documento.fileSizeValid && LegacyValidations.pushToErrorsArray(errors);          
      return errors;
    },
    FechaErrors() {
      const errors = [];
      if (!this.$v.prevForm.fecha_vencimiento.$dirty) return errors;
      !this.$v.prevForm.fecha_vencimiento.required &&
        errors.push("El campo fecha de vencimiento es requerido");
      return errors;
    },
    labelBtnCmp() {
      return this.status_value_var ? "Desmarcar" : "Marcar";
    },
    supervSelectedErrorCmp() {
      const errors = [];
      if (this.$v.supervisor_selected_var.$error) {
        errors.push("Seleccione un supervisor");
      }
      return errors;
    },
    fechaFormatted() {
      return this.prevForm.fecha_vencimiento
        ? moment(this.prevForm.fecha_vencimiento).format("DD/MM/YYYY")
        : "";
    },
    fechaMaximaFiltro() {
      // Fecha actual en formato ISO 8601 format
      return moment().format("YYYY-MM-DD");
    },
  },

  methods: {
    // <-- Funciones Regulares -->

    /**
     * @description Funcion que permite obtener el nombre completo de un supervisor
     * @param {Object} superv_param Objeto que contiene la informacion del supervisor
     * @returns {String} Nombre completo del supervisor
     */
    nombreSupervFtn(superv_param) {
      // verificacion que sea objeto
      if (typeof superv_param !== "object") {
        console.error("El parametro no es un objeto");
        return
      }

      let infoSuperv;
      if (superv_param?.EmpleadoUnidad != null) {
        infoSuperv = superv_param?.EmpleadoUnidad?.Empleado?.Persona;
      } else {
        infoSuperv = superv_param;
      }
      if (infoSuperv) {
        return `${infoSuperv.primer_nombre ? infoSuperv.primer_nombre : ""} ${infoSuperv.segundo_nombre ? infoSuperv.segundo_nombre : ""
          } ${infoSuperv.primer_apellido ? infoSuperv.primer_apellido : ""} ${infoSuperv.segundo_apellido ? infoSuperv.segundo_apellido : ""
          } ${infoSuperv.apellido_casada ? infoSuperv.apellido_casada : ""}`;
      } else {
        return "";
      }
    },

    /**
     * @description Handler del boton de marcar/desmarcar proceso
     */
    HandlerBtnFtn() {
      if (!this.status_value_var) {
        /* if (this.list_supervisores_var.length == 0) {
          this.show_modal_var = true;
        } else {
        } */
        this.ChangeStatusFtn(true);
      } else {
        this.ChangeStatusFtn(false);
      }
    },

    /**
     * @description Funcion para cerrar el modal y limpiar los campos
     */
    CloseModalFtn() {
      this.show_modal_var = false;
      this.supervisor_selected_var = null;
    },

    /**
     * @description Funcion para cerrar el modal y limpiar los campos de prevencion
     */
    CloseModalPrev() {
      this.modalPrevencion = false;
      this.prevForm.prevencion = null;
      this.prevForm.documento = null;
      this.prevForm.fecha_vencimiento = null;
      this.$v.prevForm.$reset();
    },

    // <-- Funciones Asincronas -->

    /**
     * @description Función que obtiene el detalle del proceso
     */
    async FetchDetailProcesoFtn() {
      const RESP_SC_CONST = await this.services.PacProcesos.getProceso(
        this.$route.params.idProceso
      );
      if (RESP_SC_CONST) {
        const { data } = RESP_SC_CONST;

        this.codigo_proceso_var = data.codigo_proceso;
        this.nombre_proceso_var = data.nombre_proceso;
        this.suma_mercancias_var = data.suma_mercancia;
        this.modalidad_compra_var = data.FormaContratacion.nombre;
        this.mes_inicio_var = data.fecha_inicio_proceso;
        this.mes_fin_var = data.fecha_contratacion;
        this.list_etapas_var = data.EtapaPorProcesos;
        this.id_estado_proceso_var = data.id_estado_proceso;
        this.id_seguimiento_proceso_var = data.id_seguimiento_proceso;

        // numero a booleano, 1 = true
        this.status_value_var = data.id_tipo_proceso == 2 ? true : false;
      }
    },

    /**
     * @description Función que obtiene la lista de supervisores
     */
    async FetchListSupervFtn(filter_param = null) {
      const RESP_SC_CONST = await this.services.PacProcesos.getSupervisores(
        this.$route.params.idProceso,
        filter_param
      );
      if (RESP_SC_CONST) {
        const { data, headers } = RESP_SC_CONST;

        this.paginate_supervisores_var = {
          page: Number(headers.page),
          per_page: Number(headers.per_page),
          total_rows: Number(headers.total_rows),
        };

        this.list_supervisores_var = data;
      }
    },

    async PaginateFtn(filters_param_ext) {
      const { cantidad_por_pagina, pagina } = filters_param_ext;

      const filters_param = {
        page: pagina,
        per_page: cantidad_por_pagina,
      };

      await this.FetchListSupervFtn(filters_param);
    },

    /**
     * @description Función que realiza la busqueda de usuarios
     * @param {String} search_param Parametro de busqueda
     */
    async SearchUserFtn(search_param) {
      // verificacion que sea string
      if (typeof search_param !== "string") {
        console.error("El parametro no es un string");
        return
      }


      const RESP_SC_CONST =
        await this.services.PacProcesos.getSearchedSupervisores({
          busqueda: search_param,
        });

      if (RESP_SC_CONST) {
        const { data } = RESP_SC_CONST;
        this.ctl_usuarios_var = data;
      }

    },

    /**
     * @description Función que cambia el estado del proceso
     * @param {Boolean} status_param Estado del proceso
     */
    async ChangeStatusFtn(status_param) {
      if (typeof status_param !== "boolean") {
        console.error("El parámetro status debe ser de tipo booleano");
        return;
      }
      let RESP_SC_CONST;
      if (status_param) {
        RESP_SC_CONST = await this.services.PacProcesos.markInteresPresidencia(
          this.$route.params.idProceso
        );
      } else {
        RESP_SC_CONST =
          await this.services.PacProcesos.unmarkInteresPresidencia(
            this.$route.params.idProceso
          );
      }

      if (RESP_SC_CONST) {
        this.temporalAlert({
          message: "Proceso de reserva actualizado correctamente",
          show: true,
          type: "success",
        });

        this.status_value_var = !this.status_value_var;
        this.selected_tab_var = 0;
      }
    },

    /**
     * @description Función que agrega un supervisor al proceso
     */
    async AddSupervFtn() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      } else {
        let invalid;
        // validacion que el supervisor no este agregado en la lista
        this.list_supervisores_var.forEach((item) => {
          const id_superv = item.EmpleadoUnidad.Empleado.id_usuario;
          if (id_superv == this.supervisor_selected_var.id_usuario) {
            this.temporalAlert({
              message: "El supervisor ya se encuentra agregado",
              show: true,
              type: "warning",
            });
            invalid = true;
          } else {
            invalid = false;
          }
        });
        if (invalid) return;
      }

      const bodyParam = {
        id_usuario: this.supervisor_selected_var.id_usuario,
        id_unidad: this.supervisor_selected_var.id_unidad,
      };

      const RESP_SC_CONST = await this.services.PacProcesos.postSupervisor(
        this.$route.params.idProceso,
        bodyParam
      );

      if (RESP_SC_CONST) {
        this.temporalAlert({
          message: "Supervisor agregado correctamente",
          show: true,
          type: "success",
        });
        this.FetchDetailProcesoFtn();
        if (this.list_supervisores_var.length == 0) {
          this.ChangeStatusFtn(true);
        }
        this.FetchListSupervFtn();
      }
      this.CloseModalFtn();
    },

    /**
     * @description Función que elimina un supervisor del proceso
     * @param { Object } item Objeto que contiene la informacion del supervisor
     */
    async deleteSupervFtn(item) {
      // verificacion que sea objeto
      if (typeof item !== "object") {
        console.error("El parametro no es un objeto");
        return
      }

      if (this.list_supervisores_var.length == 1) {
        this.temporalAlert({
          message: "No se puede eliminar el último supervisor",
          show: true,
          type: "warning",
        });
        return;
      }

      const RESP_SC_CONST = await this.services.PacProcesos.deleteSupervisor(
        this.$route.params.idProceso,
        item.id
      );

      if (RESP_SC_CONST) {
        this.temporalAlert({
          message: "Supervisor eliminado correctamente",
          show: true,
          type: "success",
        });
        this.FetchListSupervFtn();
      }
    },

    async savePrevProceso() {
      this.$v.prevForm.$touch();

      if (!this.$v.prevForm.$invalid) {
        this.loadingPrev = true;
        this.prevForm.id_proceso_compra = this.$route.params.idProceso;

        let formData = new FormData();
        Object.keys(this.prevForm).forEach((key) => {
          if (this.prevForm[key]) formData.append(key, this.prevForm[key]);
        });

        const { status } =
          await this.services.PrevencionesServices.guardarPrevencion(
            formData
          ).catch(() => (this.loadingPrev = false));

        if (status == 201) {
          this.temporalAlert({
            message: "Prevención generada con éxito",
            show: true,
            type: "success",
          });
          this.CloseModalPrev();
          this.$router.back();
        }

        this.loadingPrev = false;
      }
    },

    async actualizarHitosProceso() {


      const { status } =
        await this.services.PacProcesos.actualizarHitosProceso(
          this.$route.params.idProceso
        );

      if (status == 200) {
        this.temporalAlert({
          message: "Hitos actualizados con éxito",
          show: true,
          type: "success",
        });
        this.FetchDetailProcesoFtn();
        this.modalActualizacioHitos = false;
      }


    },

    hideModalCancelarProceso() {
      this.$refs.modalCancelarProceso.show = false;
    },
  },

  watch: {
    //  watch para la busqueda de usuarios
    search_ctl_usuarios_var(value) {
      if (value?.length >= 3) {
        this.loading_ctl_usuarios_var = true;
        this.SearchUserFtn(value);
        this.loading_ctl_usuarios_var = false;
      }
    },
  },

  created() {
    this.FetchDetailProcesoFtn();
    this.FetchListSupervFtn();
  },
};
</script>

<style lang="scss" scoped>
.container_span {
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
}

.container_span p {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  text-align: center;
}

.container_span p:first-child {
  margin-left: 0;
}

.container_span p:last-child {
  margin-right: 0;
}

// en pantallas pequeñas se muestra el contenido en columnas
@media screen and (max-width: 600px) {
  .container_span {
    flex-direction: column;
  }

  .container_span p {
    margin: 1rem 0;
  }
}
</style>
